import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const imageClasses = 'rounded-full mx-6'

const Knights = () => (
  <section className="relative flex flex-wrap justify-center pt-12 pb-4 md:pb-8 px-4 md:px-8 mt-16 bg-gray-400">
    <h2 className="shadow-md bg-slate-200 text-2xl rounded-3xl text-red-rooster text-center p-2 absolute -top-6 left-1/2 -translate-x-1/2 w-60 h-12">
      The Knights
    </h2>
    <div className="flex flex-wrap justify-center items-center">
      <Link to="/eric">
        <StaticImage src="../../images/eric.jpg" className={imageClasses} height={160} placeholder="blurred" />
      </Link>
      <Link to="/ben">
        <StaticImage src="../../images/ben.jpg" className={imageClasses} height={160} placeholder="blurred" />
      </Link>
      <Link to="/ryan">
        <StaticImage src="../../images/ryan.jpg" className={imageClasses} height={160} placeholder="blurred" />
      </Link>
      <Link to="/brian">
        <StaticImage src="../../images/brian.jpg" className={imageClasses} height={160} placeholder="blurred" />
      </Link>
    </div>
  </section>
)

export default Knights
