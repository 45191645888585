import React from 'react'

import Layout from '../components/common/Layout'
import GrandHistory from '../components/homepage/GrandHistory'
import TopQuote from '../components/homepage/TopQuote'
import Knights from '../components/homepage/Knights'

const IndexPage = () => (
  <Layout>
    <section className="w-full flex justify-center">
      <div className="max-w-5xl lg:mr-48">
        <TopQuote />
        <GrandHistory />
        <Knights />
      </div>
    </section>
  </Layout>
)

export default IndexPage
