import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const GrandHistory = () => (
  <section className="bg-gray-100 relative flex flex-wrap justify-center pt-12 pb-4 md:pb-8 px-4 md:px-8">
    <h2 className="shadow-md bg-slate-200 text-2xl rounded-3xl text-red-rooster text-center p-2 absolute -top-6 left-1/2 -translate-x-1/2 w-60 h-12">
      The Grand History
    </h2>
    <div className="xl:flex xl:items-center">
      <div>
        <h3 className="text-xl mb-4"><em>Before the Club of Culture Knights, there was darkness.</em></h3>
        <p className="mb-4">A once firm friendship was rent asunder by distance. Ben, the visionary, proposed a regular meeting of the minds to emulate the Enlightenment salons of old.</p>
        <p className="mb-4">Since July 2014, the Club of Culture Knights has met to expand the horizons of its members.</p>
        <p className="border-l-8 border-red-rooster p-2">
          <em>The Club of Culture Knights is America’s preeminent book club comprising four friends 	who met at American University and dispersed across the country after graduation.</em>
        </p>
      </div>
      <StaticImage
        className="ml:0 mt-8 xl:ml-12 xl:mt-0"
        src="../../images/club-shot.png"
        placeholder="blurred" 
      />
    </div>
  </section>
)

export default GrandHistory
