import React from 'react'

const TopQuote = () => (
  <section className="flex flex-col items-end p-8 md:p-12 pb-16">
    <div className="flex flex-col items-end w-full md:w-8/12">
      <p className="text-2xl text-red-rooster mb-2">We cannot change the cards we are dealt, just how we play the hand.</p>
      <p><em>- The Last Lecture</em></p>
    </div>
  </section>
)

export default TopQuote
